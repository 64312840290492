

import store from "@/store";
import { Navigation } from "@/types/Navigation";
import { Vue } from "vue-class-component";
import { RouteRecordRaw } from "vue-router";
import { State, Action } from "vuex-class";

export default class Navbar extends Vue {
  @State("availableRoutes") routes!: Navigation[];

  @Action("getAvailableRoutes") getAvailableRoutes!: RouteRecordRaw[];

  mounted() {
    store.dispatch('getAvailableRoutes', this.$router);
  }
}
