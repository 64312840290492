
import { Vue } from "vue-class-component";

export default class BackToTopButton extends Vue {
  backToTopButtonShow = false;
  created() {
    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    let rootElement = document.documentElement;
    let scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
    if (rootElement.scrollTop / scrollTotal > 0.2) {
      this.backToTopButtonShow = true;
    } else {
      this.backToTopButtonShow = false;
    }
  }
  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
