
import { Options, Vue } from "vue-class-component";
import Navbar from "@/components/Navbar.vue";
import BackToTopButton from "@/components/BackToTopButton.vue";

@Options({
  components: {
    Navbar,
    BackToTopButton,
  },
})
export default class App extends Vue {}
